<template>
  <div class="grid mt-3">
    <div class="col-12">
      <Toast />
      <h4>Vacaciones</h4>
      <span
        class="fixed flex"
        style="right: 3em; gap: 0.5em; top: 8em; z-index: 10"
        v-if="isUnsaved() && !saving"
      >
        <Button @click="cancel()" icon="pi pi-times" label="Cancelar" />
        <Button
          @click="save()"
          :disabled="checkHours"
          icon="pi pi-check"
          label="Guardar"
        />
      </span>
      <template v-if="holidays !== null">
        <div
          v-for="(_, index) in holidays"
          :key="index"
          class="flex"
          style="gap: 1em"
        >
          <div>
            <Button
              icon="pi pi-trash"
              @click="removeHoliday(index)"
              class="p-button-danger"
              aria-label="Eliminar vacaciones"
            />
          </div>
          <div class="field">
            <label class="mr-3" :for="'holiday_' + index"
              >Periodo vacacional</label
            >
            <Calendar
              :id="'holiday_' + index"
              v-model="holidays[index]"
              selectionMode="range"
              :manualInput="false"
            />
          </div>
        </div>
        <Button
          icon="pi pi-plus-circle"
          @click="addHoliday()"
          label="Añadir vacaciones"
        />
      </template>
      <ProgressSpinner v-else />
      <Divider />
    </div>
    <RepeatingAvailability
      :key="availabilityKey"
      v-model="availability"
      v-if="availability !== null"
    />
    <ProgressSpinner v-else />
    <Divider />
    <div class="col-12">
      <h4>Temporadas</h4>
    </div>
    <template v-if="seasons !== null">
      <template v-for="(_, index) in seasons" :key="index">
        <div class="flex col-12" style="gap: 1em">
          <div>
            <Button
              icon="pi pi-trash"
              @click="removeSeason(index)"
              class="p-button-danger"
              aria-label="Eliminar temporada"
            />
          </div>
          <div class="flex align-items-baseline " style="gap: 1em">
            <label :for="'seasonName' + index">Nombre</label>
            <InputText v-model="seasons[index].name" :id="'seasonName' + index" type="text" />
          </div>
          <div class="field">
            <label class="mr-3" :for="'season_' + index"
              >Periodo de temporada</label
            >
            <Calendar
              :id="'season_' + index"
              :disabledDates="disabledSeasonDates"
              panelClass="season-range"
              dateFormat="dd/mm"
              v-model="seasons[index].period"
              selectionMode="range"
              :manualInput="false"
            />
          </div>
        </div>
        <RepeatingAvailability
          :key="availabilityKey"
          v-model="seasons[index].availability"
          v-if="availability !== null"
        />
        <Divider />
      </template>
      <Button
        icon="pi pi-plus-circle"
        @click="addSeason()"
        label="Añadir temporada"
      />
    </template>
    <ProgressSpinner v-else />
    <Divider />
    <Dialog
      header="Confirmación"
      v-model:visible="showFullDayDialog"
      :style="{ width: '350px' }"
      :modal="true"
    >
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span
          >¿Está seguro de querer marcar el día
          {{ fullDayInput.toLocaleDateString() }} como completo?</span
        >
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          @click="closeFullDayDialog"
          class="p-button-text"
        />
        <Button
          label="Sí"
          icon="pi pi-check"
          @click="addFullDay"
          class="p-button-text"
          autofocus
        />
      </template>
    </Dialog>
    <div class="col-12">
      <h4>Aforo completo</h4>
    </div>
    <template v-if="fullDays !== null">
      <div class="flex">
        <Chip
          :label="formatDate(day)"
          v-for="day in fullDays"
          :key="day"
          removable
          @remove="removeFullDay(day)"
          class="mr-2"
        />
      </div>
      <div class="flex" style="gap: 1em">
        <Calendar
          id="fullDayCalendar"
          v-model="fullDayInput"
          :manualInput="false"
        />
        <Button
          icon="pi pi-plus-circle"
          @click="displayFullDayDialog()"
          label="Añadir día completo"
        />
      </div>
    </template>
    <ProgressSpinner v-else />
    <Divider />
    <div class="col-12">
      <h4>Limite de reservas simultáneas</h4>
    </div>
    <template v-if="bookingLimit !== null">
      <div class="col-12 md:col-4 lg:col-3">
        <div class="field-checkbox mb-0">
          <Checkbox
            id="bookingLimitEnabled"
            name="bookingLimitEnabled"
            :binary="true"
            v-model="bookingLimit.enabled"
          />
          <label for="bookingLimitEnabled">Activar</label>
        </div>
      </div>
      <div class="col-12 md:col-4 lg:col-3">
        <div class="field-checkbox mb-0">
          <Checkbox
            id="bookingLimitIncludePending"
            name="bookingLimitIncludePending"
            :binary="true"
            v-model="bookingLimit.includePending"
          />
          <label for="bookingLimitEnabled">Incluir reservas pendientes</label>
        </div>
      </div>
      <div class="field col-12">
        <label class="pr-2" for="inputBookingLimit">Límite de reservas</label>
        <InputNumber
          id="inputBookingLimit"
          v-model="bookingLimit.bookingLimit"
        ></InputNumber>
      </div>
    </template>
    <ProgressSpinner v-else />
  </div>
</template>

<script>
import {
  parseTimeFromApi,
  parseDateFromApi,
  formatApiDateForHuman,
  formatTimeForApi,
  addDays,
  formatDateForApi,
  formatDateOnlyDayAndMonth,
  parseMonthDayRangeFromApi,
} from '../../utils/dates';
import { getLastResult } from '../../utils/util';
import { cloneDeep } from 'lodash';
import OrganizationService from '../../service/OrganizationService';

export default {
  created() {
    this.organizationService = new OrganizationService();
  },
  mounted() {
    const fetchData = async () => {
      const config = await this.organizationService.getConfig(
        this.$route.params.id
      );
      this.parseConfig(config);
    };

    fetchData();
  },
  data() {
    return {
      showFullDayDialog: false,
      organizationService: null,
      saving: false,
      originalAvailability: null,
      availability: null,
      originalHolidays: null,
      holidays: null,
      availabilityKey: 0,
      originalSeasons: null,
      seasons: null,
      fullDays: null,
      fullDayInput: new Date(),
      disabledSeasonDates: [
        new Date('2016, 02, 29'),
        new Date('2020, 02, 29'),
        new Date('2024, 02, 29'),
        new Date('2028, 02, 29'),
      ],
      bookingLimit: null,
      originalBookingLimit: null,
    };
  },
  methods: {
    closeFullDayDialog() {
      this.showFullDayDialog = false;
    },
    removeFullDay(day) {
      this.saving = true;
      this.showFullDayDialog = false;
      const promise = this.organizationService.removeFullDay(
        this.$route.params.id,
        day
      );
      promise.then((organization) => {
        this.parseConfig(organization.config);
        this.$toast.add({
          severity: 'success',
          summary: 'Día eliminado',
          detail: 'Día completo eliminado con éxito.',
          life: 3000,
        });
        this.saving = false;
      });
    },
    addFullDay() {
      this.saving = true;
      this.showFullDayDialog = false;
      const promise = this.organizationService.addFullDay(
        this.$route.params.id,
        this.fullDayInput
      );
      promise.then((organization) => {
        this.parseConfig(organization.config);
        this.$toast.add({
          severity: 'success',
          summary: 'Día añadido',
          detail: 'Día completo añadido con éxito.',
          life: 3000,
        });
        this.saving = false;
      });
    },
    formatDate(date) {
      return formatApiDateForHuman(date);
    },
    parseConfig(config) {
      const bookingLimit = {
        enabled: config.bookingLimit?.enabled ?? false,
        includePending:
          config.bookingLimit?.includePendingBookings ??
          false,
        bookingLimit: config.bookingLimit?.bookingLimit ?? 10,
      };
      this.bookingLimit = bookingLimit;
      this.originalBookingLimit = { ...bookingLimit };
      this.fullDays = config.availabilityConfig.fullDays;

      const parseAvailabilityConfig = (availabiltyConfig) => {
        const { breakStart, breakEnd, ...repeatingAvailabilityConfig } =
          availabiltyConfig;
        let availabilityData = {};
        Object.entries(repeatingAvailabilityConfig).forEach(
          ([key, configValue]) => {
            configValue.workingHoursStart = configValue?.workingHoursStart
              ? parseTimeFromApi(configValue.workingHoursStart)
              : null;
            configValue.workingHoursEnd = configValue?.workingHoursEnd
              ? parseTimeFromApi(configValue.workingHoursEnd)
              : null;
            availabilityData[key] = configValue;
          }
        );
        const breaks = {
          start: null,
          end: null,
        };

        if (breakStart !== null) {
          breaks.start = parseTimeFromApi(breakStart);
          if (breakEnd !== null) {
            breaks.end = parseTimeFromApi(breakEnd);
          }
        }

        return {
          repeatingAvailabilityConfig: availabilityData,
          breakStart: breaks.start,
          breakEnd: breaks.end,
        };
      };

      const holidaysData = config.holidayConfig.holidays.map((holiday) => [
        parseDateFromApi(holiday.holidayStartDate),
        parseDateFromApi(holiday.holidayEndDate),
      ]);
      this.originalHolidays = cloneDeep(holidaysData);
      this.holidays = holidaysData;

      const availabiltyConfig =
        config.availabilityConfig.repeatingAvailabilityConfig;
      const availabilityData = parseAvailabilityConfig(availabiltyConfig);

      this.availability = cloneDeep(availabilityData);
      this.originalAvailability = availabilityData;

      const seasonsData = config.availabilityConfig.seasons.map((season) => {
        return {
          name: season.name ?? '',
          period: parseMonthDayRangeFromApi(
            season.seasonStart,
            season.seasonEnd
          ),
          availability: parseAvailabilityConfig(
            season.repeatingAvailabilityConfig
          ),
        };
      });
      this.originalSeasons = cloneDeep(seasonsData);
      this.seasons = seasonsData;
    },
    formatAvailabilityForApi(availability) {
      const apiAvailability = {
        breakStart:
          availability.breakStart !== null
            ? formatTimeForApi(availability.breakStart)
            : null,
        breakEnd:
          availability.breakEnd !== null
            ? formatTimeForApi(availability.breakEnd)
            : null,
      };
      Object.entries(availability.repeatingAvailabilityConfig).forEach(
        ([key, configValue]) => {
          configValue.workingHoursStart = formatTimeForApi(
            configValue.workingHoursStart
          );
          configValue.workingHoursEnd = formatTimeForApi(
            configValue.workingHoursEnd
          );
          apiAvailability[key] = configValue;
        }
      );
      return apiAvailability;
    },
    save() {
      this.saving = true;
      const organizationId = this.$route.params.id;
      let savePromises = [];
      if (
        JSON.stringify(this.holidays) !== JSON.stringify(this.originalHolidays)
      ) {
        savePromises.push(
          this.organizationService.putHolidays(organizationId, {
            holidays: this.holidays.map(([start, end]) => ({
              holidayStartDate: formatDateForApi(start),
              holidayEndDate: formatDateForApi(end),
            })),
          })
        );
      }
      if (
        JSON.stringify(this.availability) !==
          JSON.stringify(this.originalAvailability) ||
        JSON.stringify(this.seasons) !== JSON.stringify(this.originalSeasons)
      ) {
        const season = this.seasons.map(
          ({ name, period: [start, end], availability }) => ({
            name,
            seasonStart: formatDateOnlyDayAndMonth(start),
            seasonEnd: formatDateOnlyDayAndMonth(end),
            repeatingAvailabilityConfig:
              this.formatAvailabilityForApi(availability),
          })
        );

        savePromises.push(
          this.organizationService.putAvailabilityConfig(
            organizationId,
            this.formatAvailabilityForApi(this.availability),
            season
          )
        );
      }
      if (
        JSON.stringify(this.bookingLimit) !==
        JSON.stringify(this.originalBookingLimit)
      ) {
        savePromises.push(
          this.organizationService.putBookingLimit(
            organizationId,
            this.bookingLimit
          )
        );
      }

      getLastResult(savePromises)
        .then((organization) => {
          this.parseConfig(organization.config);
          this.$toast.add({
            severity: 'success',
            summary: 'Configuración guardada',
            detail: 'Configuración guardada con éxito.',
            life: 3000,
          });
        })
        .catch((error) => {
          console.log(error);
          this.$toast.add({
            severity: 'error',
            summary: 'Error guardando la configuración',
            detail:
              'Ha habido un error guardando la configuración, por favor inténtalo más tarde',
            life: 3000,
          });
        })
        .finally(() => {
          this.saving = false;
          this.availabilityKey += 1;
        });
    },
    removeHoliday(index) {
      this.holidays.splice(index, 1);
    },
    addHoliday() {
      this.holidays.push([new Date(), addDays(new Date(), 7)]);
    },
    removeSeason(index) {
      this.seasons.splice(index, 1);
    },
    displayFullDayDialog() {
      this.showFullDayDialog = true;
    },
    addSeason() {
      this.seasons.push({
        period: [new Date(), addDays(new Date(), 7)],
        availability: cloneDeep(this.availability),
      });
    },
    isUnsaved() {
      return (
        JSON.stringify(this.availability) !==
          JSON.stringify(this.originalAvailability) ||
        JSON.stringify(this.holidays) !==
          JSON.stringify(this.originalHolidays) ||
        JSON.stringify(this.seasons) !== JSON.stringify(this.originalSeasons) ||
        JSON.stringify(this.bookingLimit) !==
          JSON.stringify(this.originalBookingLimit)
      );
    },
    cancel() {
      this.availability = cloneDeep(this.originalAvailability);
      this.holidays = cloneDeep(this.originalHolidays);
      this.availabilityKey += 1;
      this.seasons = cloneDeep(this.originalSeasons);
    },
    isAvailabilityValid(availability) {
      let check = Object.values(availability.repeatingAvailabilityConfig).some(
        (day) =>
          !day.workingHoursStart ||
          !day.workingHoursEnd ||
          day.workingHoursStart > day.workingHoursEnd
      );
      const breakStart = availability.breakStart;
      const breakEnd = availability.breakEnd;
      if (!breakStart !== !breakEnd) {
        check = true;
      } else if (breakStart && breakStart > breakEnd) {
        check = true;
      }
      return check;
    },
  },
  computed: {
    checkHours() {
      return (
        this.isAvailabilityValid(this.availability) ||
        this.seasons.some((season) =>
          this.isAvailabilityValid(season.availability)
        )
      );
    },
  },
};
</script>

<style>
@media screen and (max-width: 991px) {
  .divider-wrapper {
    display: none;
  }
  .divider-wrapper-mobile {
    display: flex !important;
  }
}
.season-range .p-datepicker-year {
  display: none !important;
}
.season-range.p-datepicker-monthpicker .p-datepicker-header {
  display: none !important;
}
</style>
